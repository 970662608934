<template>
  <alliance-profile-component />
</template>

<script>
import AllianceProfileComponent from "@/components/alliances/AllianceProfileComponent";

export default {
  name: "AllianceProfile",
  title: "Perfil de la Alianza | Baja California Health Tourism",
  components: { AllianceProfileComponent },
};
</script>

<style scoped></style>
